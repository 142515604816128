import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('app'));

const restApiHost = 'https://dragonclickerpolicy.com';

export default restApiHost;

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


